import {
	Button,
	Card,
	DatePicker,
	Descriptions,
	Form,
	Select,
	Table,
} from 'antd';
import dayjs from 'dayjs';
import { includeCheckIn, includeLease, includeMonthly } from 'blog/type-file';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { endOfMonth, startOfMonth } from '../../../utils/timeUtil';
import { TrpcBase } from '../../../utils/trpc';
import { useUserAgent } from '../../../utils/userAgent';
import { apartmentDetailColumns, dateFormatShow } from '../constants';
import { getFloorLevel } from '../edit/getFloorLevel';
import { getOptionValueOrLabel } from '../edit/getOptionValueOrLabel';
import { floor } from '../listModel';
import { useApartmentList } from '../overview/useApartmentList';
import { useOverviewState } from '../overview/useOverviewState';
import { printSplitSymbol } from '../print/printSplitSymbol';
import { globalAntdPagination } from '../util/globalAntdPagination';
import { timestampStringToDayjs } from '../util/timestampStringToDayjs';
import { ApplyItemMenuRender } from './ApplyItemMenuRender';
import { roomStatusEnumeration } from 'blog/type-file';
import { MonthTimePicker } from '../overview/MonthTimePicker';
// dayjs.extend(isBetween);

export default function OldList() {
	const navigate = useNavigate();
	const { isMobile, isPC, formLayout } = useUserAgent();

	const [stateParams, setStateParams] = useOverviewState();

	const timeSearchParams = timestampStringToDayjs(stateParams?.time);
	const floorSearchParams = stateParams?.floor;

	const [form] = Form.useForm();
	const formTime = Form.useWatch('time', form) ?? dayjs();
	const formFloor = Form.useWatch('floor', form);

	const { list } = useApartmentList({
		startTime: startOfMonth(formTime).valueOf(),
		endTime: endOfMonth(formTime).valueOf(),
		floorNumber: floorSearchParams,
		category: [includeMonthly, includeCheckIn, includeLease],
		chargeStatus: '0',
	});

	const delAction = TrpcBase.apartment.del.useMutation();
	const { data: configure } = TrpcBase.apartment.getRooms.useQuery();
	const roomConfigure = (configure ?? [])
		.filter((i) => i.enabled === 1)
		.map((i) => {
			return {
				...i,
				floorLevel: getFloorLevel(i.roomNumber),
			};
		})
		.filter((i) => i.floorLevel === floorSearchParams);

	const [selectedRowKeys, setSelectedRowKeys]: [any, any] = useState([]);

	const hasSelected = selectedRowKeys.length > 0;

	// 审核单里没有的房间
	const roomsNotInAuditList = roomConfigure
		.filter((i) => !list.map((p) => p.roomNumber).includes(i.roomNumber))
		.map((i) => {
			// todo 非当月的单不用显示房间状态 或者考虑每日快照
			// formTime === dayjs(). &&
			if (i.roomStatus === roomStatusEnumeration.busy) {
				i.verify = '尚未录入';
			}
			if (i.roomStatus === roomStatusEnumeration.open) {
				i.verify = '正常';
			}

			i.key = i.roomNumber;
			return i;
		});

	const dataList = list
		.concat(roomsNotInAuditList)
		.sort((i: any, j: any) => i.roomNumber - j.roomNumber);

	return (
		<div>
			<Card>
				<Form
					layout={formLayout}
					form={form}
					initialValues={{
						time: timeSearchParams,
						floor: floorSearchParams,
					}}
					onValuesChange={(v, all) => {
						setStateParams((pre) => {
							return {
								...pre,
								floor: all?.floor?.toString(),
								time: all?.time?.valueOf(),
							};
						});
					}}
					onFinish={(values) => {}}
				>
					<MonthTimePicker />
					<Form.Item label="楼层" name="floor">
						<Select
							style={{ width: 100 }}
							options={floor.map((i) => {
								return {
									label: `${i}楼`,
									value: i.toString(),
								};
							})}
							allowClear={true}
						/>
					</Form.Item>

					{hasSelected && (
						<Button
							onClick={() => {
								navigate(
									`/apartment/print?uuid=${list
										.filter((i) => selectedRowKeys.includes(i.uuid))
										.map((r) => r.uuid)
										.join(printSplitSymbol)}`,
								);
							}}
						>
							打印
						</Button>
					)}
				</Form>
			</Card>

			<Table
				pagination={{ ...globalAntdPagination, pageSize: 20 }}
				rowSelection={
					isPC
						? {
								selectedRowKeys,
								onChange: (r) => {
									setSelectedRowKeys(r);
								},
							}
						: undefined
				}
				dataSource={dataList}
				columns={[
					{
						key: 'roomNumber',
						dataIndex: 'roomNumber',
						title: '房间号',
						width: 80,
					},
					{
						key: 'status',
						dataIndex: 'status',
						title: '状态',
						width: 80,
						render: (_, record) => {
							return record?.roomStatus === roomStatusEnumeration.open
								? '空置'
								: '租用';
						},
					},
					{
						key: 'category',
						dataIndex: 'category',
						title: '类型',
						width: 80,
						render: (value, record, index) => {
							return getOptionValueOrLabel(value);
						},
					},
					{
						key: 'rent',
						dataIndex: 'rent',
						title: '房租',
						width: 80,
					},
					{
						width: 120,
						title: '入住时间',
						dataIndex: 'checkInTime',
						sorter: (a, b) => {
							return (
								dayjs(a.checkInTime).valueOf() - dayjs(b.checkInTime).valueOf()
							);
						},
						render: (v: number) => {
							return dayjs(v).format(dateFormatShow);
						},
					},
					{
						key: 'checkOutTime',
						dataIndex: 'checkOutTime',
						title: '退房时间',
						width: 120,
						render: (v: number) => {
							return dayjs(v).format(dateFormatShow);
						},
					},
					{
						key: 'remark',
						dataIndex: 'remark',
						title: '备注',
						width: 400,
					},
					{
						key: 'verify',
						dataIndex: 'verify',
						title: '数据校验',
						width: 300,
					},

					{
						title: '操作',
						key: 'action',
						render: (v, record) => {
							return <ApplyItemMenuRender text={v} record={record} />;
						},
					},
				].filter((i) =>
					isMobile
						? ['roomNumber', 'category', 'action'].includes(i.key)
						: true,
				)}
				expandable={
					isPC
						? {
								expandedRowRender: (record) => (
									<Descriptions
										title="用度详情"
										layout="vertical"
										column={{ xxl: 6, xl: 6, lg: 4, md: 4, sm: 2, xs: 1 }}
									>
										{apartmentDetailColumns.map((a) => (
											<Descriptions.Item key={a.key} label={a.title}>
												{record[a.key]}
											</Descriptions.Item>
										))}
									</Descriptions>
								),
								rowExpandable: (record) => record.name !== 'Not Expandable',
							}
						: {}
				}
			/>
		</div>
	);
}
